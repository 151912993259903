import { withAuthenticationRequired } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import LocationLayout from "../components/location/location-layout";
import Loading from "../components/loading";
import {
  getAllLocations,
  OrganizedLocations,
  organizeLocations,
} from "../workers/location";
import { useEffect, useState } from "react";

const LocationConfigurationPage = () => {
  const warehouse = "Golborne"; // Replace with your warehouse name
  const [locations, setLocations] = useState<OrganizedLocations>();

  useEffect(() => {
    async function fetchLocations() {
      const getLocations = await getAllLocations(warehouse);
      setLocations(organizeLocations(getLocations.locations));
    }
    fetchLocations();
  }, [warehouse, setLocations]);

  return (
    <Container>
      <h1>Location Configuration</h1>
      <p>Configuration for all locations</p>
      <p>Warehouse: {warehouse}</p>
      {locations ? <LocationLayout locations={locations} /> : null}
    </Container>
  );
};

export default withAuthenticationRequired(LocationConfigurationPage, {
  onRedirecting: () => <Loading />,
});
