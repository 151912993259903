import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import {
  Plus,
  Upload,
  Save,
  CheckCircle,
  ExclamationCircle,
} from "react-bootstrap-icons";

const createLocationWorker = createWorkerFactory(
  () => import("../../workers/location")
);

interface ItemLocation {
  id: string;
  items: string[];
  location: string;
  status?: "pending" | "success" | "error" | "loading";
  error?: string;
}

export const BulkItemLocationScanner = () => {
  const [entries, setEntries] = useState<ItemLocation[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<string>();
  const darkMode = useCircleDarkMode();
  const locationWorker = createLocationWorker();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addNewEntry = () => {
    setEntries([
      ...entries,
      {
        id: Date.now().toString(),
        items: [""],
        location: "",
      },
    ]);
  };

  const updateEntry = (id: string, field: keyof ItemLocation, value: any) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) => {
        if (entry.id === id) {
          if (field === "items") {
            const items = (value as string)
              .split(",")
              .map((item) => item.trim().toUpperCase())
              .filter((item) => item.length > 0);
            return { ...entry, items };
          }
          return { ...entry, [field]: value };
        }
        return entry;
      })
    );
  };

  const removeEntry = (id: string) => {
    setEntries(entries.filter((entry) => entry.id !== id));
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const lines = text.split("\n");

      // Try to detect the CSV format by checking the first non-empty line
      const firstLine = lines.find((line) => line.trim().length > 0);
      const isSimpleFormat = firstLine?.includes("','"); // Check for 'ITEM','LOCATION' format

      const newEntries: ItemLocation[] = [];
      const locationMap = new Map<string, string[]>();

      if (isSimpleFormat) {
        // Process 'ITEM','LOCATION' format
        lines
          .filter((line) => line.trim().length > 0)
          .forEach((line) => {
            const [item, location] = line
              .split(",")
              .map((s) => s.trim().replace(/^'|'$/g, "").toUpperCase());

            if (locationMap.has(location)) {
              locationMap.get(location)?.push(item);
            } else {
              locationMap.set(location, [item]);
            }
          });

        // Convert location map to entries
        for (const [location, items] of locationMap) {
          newEntries.push({
            id: Date.now().toString() + Math.random(),
            items,
            location,
          });
        }
      } else {
        // Process original format (items;items,location)
        lines
          .filter((line) => line.trim().length > 0)
          .forEach((line) => {
            const [itemsStr, location] = line.split(",").map((s) => s.trim());
            const items = itemsStr
              .split(";")
              .map((i) => i.trim().toUpperCase());

            if (items.length > 0 && location) {
              newEntries.push({
                id: Date.now().toString() + Math.random(),
                items,
                location: location.toUpperCase(),
              });
            }
          });
      }

      setEntries([...entries, ...newEntries]);
    };
    reader.readAsText(file);
    // Reset file input so the same file can be uploaded again
    event.target.value = "";
  };

  const submitEntries = async () => {
    setIsSubmitting(true);
    setGlobalError(undefined);

    try {
      // Group entries by location
      const locationGroups = new Map<string, ItemLocation[]>();
      entries.forEach((entry) => {
        if (!entry.location || entry.status === "success") return;

        if (!locationGroups.has(entry.location)) {
          locationGroups.set(entry.location, []);
        }
        locationGroups.get(entry.location)?.push(entry);
      });

      const updatedEntries = [...entries];

      // Process each location group
      for (const [location, locationEntries] of locationGroups) {
        // Mark entries as loading
        locationEntries.forEach((entry) => {
          const index = updatedEntries.findIndex((e) => e.id === entry.id);
          if (index !== -1) {
            updatedEntries[index] = { ...entry, status: "loading" };
          }
        });
        setEntries(updatedEntries.slice());

        try {
          // Combine all items for this location
          const allItems = locationEntries.flatMap((entry) => entry.items);
          await locationWorker.storeItemsLocation(allItems, location);

          // Update all entries for this location as successful
          locationEntries.forEach((entry) => {
            const index = updatedEntries.findIndex((e) => e.id === entry.id);
            if (index !== -1) {
              updatedEntries[index] = { ...entry, status: "success" };
            }
          });
        } catch (error) {
          // Mark all entries in this location group as failed
          locationEntries.forEach((entry) => {
            const index = updatedEntries.findIndex((e) => e.id === entry.id);
            if (index !== -1) {
              updatedEntries[index] = {
                ...entry,
                status: "error",
                error: (error as Error).message,
              };
            }
          });
        }

        setEntries(updatedEntries.slice());
      }
    } catch (error) {
      setGlobalError("Error processing entries. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearAllEntries = () => {
    if (window.confirm("Are you sure you want to clear all entries?")) {
      setEntries([]);
      // Reset file input when clearing entries
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const clearSubmittedEntries = () => {
    if (
      window.confirm("Are you sure you want to clear all submitted entries?")
    ) {
      setEntries(entries.filter((entry) => entry.status !== "success"));
    }
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <h3>Bulk Location</h3>
        </Col>
        <Col className="d-flex justify-content-end gap-2">
          <Button variant="primary" onClick={addNewEntry}>
            <Plus /> Add Entry
          </Button>
          <Button variant="info" style={{ position: "relative" }}>
            <Upload /> Upload CSV
            <Form.Control
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          </Button>
          <Button
            variant="success"
            onClick={clearSubmittedEntries}
            disabled={!entries.some((e) => e.status === "success")}
          >
            Clear Submitted
          </Button>
          <Button
            variant="danger"
            onClick={clearAllEntries}
            disabled={entries.length === 0}
          >
            Clear All
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover variant={darkMode ? "dark" : "light"}>
        <thead>
          <tr>
            <th>Items (comma-separated)</th>
            <th>Location</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry) => (
            <tr key={entry.id}>
              <td>
                <Form.Control
                  type="text"
                  value={entry.items.join(", ")}
                  onChange={(e) =>
                    updateEntry(entry.id, "items", e.target.value)
                  }
                  style={{ fontFamily: "Menlo, monospace" }}
                  readOnly={entry.status === "success"}
                  disabled={entry.status === "success"}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={entry.location}
                  onChange={(e) =>
                    updateEntry(entry.id, "location", e.target.value)
                  }
                  style={{ fontFamily: "Menlo, monospace" }}
                  readOnly={entry.status === "success"}
                  disabled={entry.status === "success"}
                />
              </td>
              <td>
                {entry.status === "success" && (
                  <span className="text-success">
                    Stored <CheckCircle />
                  </span>
                )}
                {entry.status === "loading" && (
                  <span className="text-primary">
                    <Spinner animation="border" size="sm" className="me-2" />
                    Submitting...
                  </span>
                )}
                {entry.status === "error" && (
                  <span className="text-danger">
                    <ExclamationCircle /> {entry.error}
                  </span>
                )}
              </td>
              <td>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => removeEntry(entry.id)}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {entries.length > 0 && (
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={submitEntries}
              disabled={
                isSubmitting || entries.every((e) => e.status === "success")
              }
            >
              <Save className="me-2" />
              {isSubmitting ? "Submitting..." : "Submit All"}
            </Button>
          </Col>
        </Row>
      )}

      {globalError && (
        <Alert variant="danger" className="mt-3">
          {globalError}
        </Alert>
      )}
    </Container>
  );
};

export default BulkItemLocationScanner;
