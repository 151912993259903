import React, { useContext, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { UserAuthenticatedContext } from "../../components/profile";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { generatePlaceholderTableRows } from "../../components/placeholders";
import { Client } from "../../external";
import { formatDateFromTimestamp } from "../../helpers/datetime";
import { CheckLg, X } from "react-bootstrap-icons";

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

const Clients = () => {
  const [clients, setClients] = React.useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = React.useState<boolean>(false);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const clientsWorker = useWorker(createClientsWorker);

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  const navigate = useNavigate();

  return (
    <Container>
      <h1>Clients</h1>
      <Row>
        <Col className="mb-2">
          {userAuthenticatedContext.scopes?.includes("create:client-item") ? (
            <Button variant="success" onClick={() => navigate("/client/new")}>
              New Client
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Google Sheet ID</th>
                <th>Google Sheet Write Back</th>
                <th>Last Updated</th>
                {userAuthenticatedContext.scopes?.includes(
                  "edit:client-item"
                ) ? (
                  <th>Details</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {!clientsLoaded ? (
                <>{generatePlaceholderTableRows(6, 6)}</>
              ) : (
                [...clients]
                  .sort((a, b) => a.clientName.localeCompare(b.clientName))
                  .map((client) => (
                    <ClientsRow
                      key={client.clientId}
                      client={client}
                      navigate={navigate}
                      scopes={userAuthenticatedContext.scopes}
                    />
                  ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

const ClientsRow = ({
  client,
  navigate,
  scopes,
}: {
  client: Client;
  navigate: NavigateFunction;
  scopes?: string[];
}) => (
  <tr>
    <td>{client.clientId}</td>
    <td>{client.clientName}</td>
    <td>
      <a
        href={`https://docs.google.com/spreadsheets/d/${client.googleSheetId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <code>{client.googleSheetId}</code>
      </a>
    </td>
    <td>{client.sheetWriteBack ? <CheckLg /> : <X />}</td>
    <td>{formatDateFromTimestamp(client.lastUpdatedTimestamp)}</td>
    {scopes?.includes("edit:client-item") ? (
      <td>
        <Button
          size="sm"
          onClick={() => {
            navigate(`/client/${client.clientId}`);
          }}
        >
          Open
        </Button>
      </td>
    ) : null}
  </tr>
);

export default withAuthenticationRequired(Clients, {
  onRedirecting: () => <Loading />,
});
