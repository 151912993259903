import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import GoogleAppsScriptIframe from "../components/google-apps-script-iframe";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useMemo, useContext } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import ImageRetrievalTool from "./image/retrieval-tool";
import CroppingTool from "./image/cropping-tool";
import ItemLocationScanner from "../components/location/item-scanner";
import FinanceUploadComponent from "../components/finance/upload-finance";
import CubeEstimator from "./cube-estimator";
import { UserAuthenticatedContext } from "../components/profile";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { useDarkMode } from "usehooks-ts";

const tabHeight = 1200;
const AppsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [key, setKey] = useState<string>(searchParams.get("tab") ?? "");

  // When the key changes, update the URL search params
  useEffect(() => {
    setSearchParams({ tab: key });
  }, [key, setSearchParams]);

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const hasImageDownloadPermissions = userAuthenticatedContext.scopes?.includes(
    "use:image-downloader"
  );
  const imageRetrievalToolEnabled =
    useFeatureFlagEnabled("imageRetrievalTool") && hasImageDownloadPermissions;
  const imageRetrievalToolLink = useFeatureFlagPayload(
    "imageRetrievalTool"
  ) as string;

  const hasFinanceUploadPermissions = userAuthenticatedContext.scopes?.includes(
    "upload:finance-data"
  );

  const darkMode = useDarkMode();

  const tabsList = useMemo(
    () => [
      <Tab eventKey="pricing" key="pricing" title="Pricing Dashboard Portal">
        <GoogleAppsScriptIframe
          src="https://script.google.com/a/macros/clearcyclecloud.uk/s/AKfycbwWZBUUY7Xj8zR39SUZCIwFocBdITu2qiZJGlNSW1L7Egkr8R4FmEI0Lcqhv8VP_EG3/exec"
          title="Pricing Dashboard Portal"
          style={{
            height: tabHeight,
            margin: "0.5em",
          }}
        />
      </Tab>,

      <Tab
        key={"image-urls-from-page"}
        eventKey={"image-urls-from-page"}
        title="Get Image URLs from page"
      >
        <h2>Get Image URLs from Page</h2>
        <ImageRetrievalTool />
      </Tab>,
      imageRetrievalToolEnabled ? (
        <Tab
          eventKey={"image-retrieval-tool"}
          key="image-retrieval-tool"
          title="Image Retrieval Tool"
        >
          <GoogleAppsScriptIframe
            title={"Image Retrieval Tool"}
            src={imageRetrievalToolLink}
            style={{ width: "100%", height: "800px" }}
          ></GoogleAppsScriptIframe>
        </Tab>
      ) : null,
      <Tab key="cropping-tool" eventKey={"cropping-tool"} title="Crop Image">
        <CroppingTool />
      </Tab>,
      <Tab
        key={"item-location-scanner"}
        eventKey={"item-location-scanner"}
        title="Item Location Scanner"
      >
        <ItemLocationScanner key="item-location-scanner" />
      </Tab>,
      hasFinanceUploadPermissions ? (
        <Tab
          key={"finance-tdb-upload"}
          eventKey={"finance-tdb-upload"}
          title="Finance TDB Upload"
        >
          <FinanceUploadComponent />
        </Tab>
      ) : null,
      <Tab
        key={"cube-estimator"}
        title={"Cube Estimator"}
        eventKey={"cube-estimator"}
      >
        <CubeEstimator />
      </Tab>,
      <Tab eventKey="pm-image" key="pm-image" title="Stock Images (old)">
        <GoogleAppsScriptIframe
          src="https://script.google.com/macros/s/AKfycbySLpoFk7HJ1cYEAz7BKts0xVVQlAgAxAC90wC8M1sh1gM6Uyx9R5EId3wIKipdraZw/exec"
          title="Stock Images (old)"
          style={{
            height: tabHeight,
            margin: "0.5em",
          }}
        />
      </Tab>,
      <Tab
        eventKey="pallet-scanner"
        key="pallet-scanner"
        title="Outbound Auction Pallet Scanner (old)"
      >
        <GoogleAppsScriptIframe
          src="https://script.google.com/macros/s/AKfycbyUQC9u4odUPVRv2IbNZ2di2ktmKDZLzGTYmlyBj66zk-K7AM4b-OMkXVh2LhV0K8YJPA/exec"
          title="Pallet Scanner (old)"
          style={{
            height: tabHeight,
            margin: "0.5em",
          }}
        />
      </Tab>,
    ],
    [
      hasFinanceUploadPermissions,
      imageRetrievalToolEnabled,
      imageRetrievalToolLink,
    ]
  );

  useEffect(() => {
    if (!key) {
      setKey(tabsList[0]?.key as string);
    }
  }, [key, tabsList]);

  const NavLinkExternal = ({
    redirectLink,
    title,
    showExternalText = true,
  }: {
    redirectLink: string;
    title: string;
    showExternalText?: boolean;
  }) => {
    return (
      <Nav.Item>
        <Nav.Link
          onClick={(e) => {
            e.preventDefault();
            window.open(redirectLink, "_blank");
          }}
        >
          <span>{title}</span>
          <BoxArrowUpRight
            style={{ marginLeft: "1em", marginBottom: "0.3em" }}
          />
          <br />
          {showExternalText ? (
            <span
              style={{
                fontSize: "0.8em",
                color: darkMode ? "lightgray" : "darkgray",
              }}
            >
              Opens in new tab
            </span>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <Container>
      <Tab.Container
        id="apps-tab-container"
        activeKey={key}
        onSelect={(k) => setKey(k!)}
      >
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {/** Pricing */}
              <Nav.Item className="nav-header">
                <span>Pricing</span>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pricing">Pricing Dashboard Portal</Nav.Link>
              </Nav.Item>
              {/** Finance */}
              {hasFinanceUploadPermissions ? (
                <>
                  <Nav.Item className="nav-header">
                    <span>Finance</span>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="finance-tdb-upload">
                      Finance TDB Upload
                    </Nav.Link>
                  </Nav.Item>
                </>
              ) : null}
              {/** Image Tools */}
              <Nav.Item className="nav-header">
                <span>Image Tools</span>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="image-urls-from-page">
                  Get Image URLs from page
                </Nav.Link>
              </Nav.Item>
              {imageRetrievalToolEnabled && (
                <Nav.Item>
                  <Nav.Link eventKey="image-retrieval-tool">
                    Image Retrieval Tool
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="cropping-tool">Crop Image</Nav.Link>
              </Nav.Item>
              <NavLinkExternal
                redirectLink="/image-upload-tool"
                title="Image Upload Tool"
              />
              {/** Other Tools */}
              <Nav.Item className="nav-header">
                <span>Other Tools</span>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cube-estimator">Cube Estimator</Nav.Link>
              </Nav.Item>
              <NavLinkExternal
                redirectLink="/item-location"
                title="Item Location"
              />
              {/** External Links */}
              <Nav.Item className="nav-header">
                <span>External Links</span>
              </Nav.Item>
              <NavLinkExternal
                redirectLink="https://app.cluvio.com/dashboards"
                title="Cluvio"
                showExternalText={false}
              />
              <Nav.Item className="nav-header">
                <span>Old Tools</span>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pm-image">Stock Images (old)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pallet-scanner">
                  Outbound Auction Pallet Scanner (old)
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              {tabsList
                .filter((t) => !!t)
                .map((t) => (
                  <Tab.Pane eventKey={t.key!} key={t.key!}>
                    {t.props.children}
                  </Tab.Pane>
                ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default withAuthenticationRequired(AppsPage, {
  onRedirecting: () => <Loading />,
});
