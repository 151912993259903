import React, { useContext, useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Alert from "react-bootstrap/Alert";
import Label from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form as FormikForm } from "formik";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

import { UserAuthenticatedContext } from "../components/profile";
import Loading from "../components/loading";
import InventoryInboundCard from "../components/inbound/inbound-card";
import InboundNotesModal from "../components/inbound/inbound-notes-modal";
import InboundModal from "../components/inbound/inbound-modal";
import InboundNewItemModal from "../components/inbound/inbound-new-item-modal";
import { InventoryItem } from "../external";

const createInventoryWorker = createWorkerFactory(
  () => import("../workers/inventory")
);

const InboundLoadPage = () => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const inventoryWorker = useWorker(createInventoryWorker);

  const [isLoadReferencesLoaded, setIsLoadReferencesLoaded] =
    useState<boolean>(false);
  const [isLoadReferenceItemsLoaded, setIsLoadReferenceItemsLoaded] =
    useState<boolean>(false);

  const [loadReferences, setLoadReferences] = useState<
    { loadReference: string; expectedDeliveryDate: string | null }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedResult, setSelectedResult] = useState<InventoryItem[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [showNewItemModal, setShowNewItemModel] = useState<boolean>(false);

  const [filterCompleted, setFilterCompleted] = useState<boolean>(false);
  const [notesSaved, setNotesSaved] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [itemsConfirmedOverride, setItemsConfirmedOverride] =
    useState<boolean>(false);
  const [inboundProcessed, setInboundProcessed] = useState<boolean>(false);

  const [showSavingError, setShowSavingError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const handleOptionSelect = async (event: any) => {
    setShowSuccess(false);
    setShowSavingError(false);
    setInboundProcessed(false);
    setItemsConfirmedOverride(false);
    setIsLoadReferenceItemsLoaded(false);
    const option = event.target.value;
    setSelectedOption(option);

    const items = await inventoryWorker.getInventoryItemsByLoadReferences(
      option,
      userAuthenticatedContext.token!
    );

    setSelectedResult(items);
    setIsLoadReferenceItemsLoaded(true);

    items.forEach((item: InventoryItem) => {
      if (item.inboundCompleted) {
        setInboundProcessed(true);
      }
    });
  };

  const handleShowModal = (index: number) => {
    setIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowNotesModal = (index: number, data: InventoryItem) => {
    setShowNotesModal(true);
    setIndex(index);
  };

  const handleCloseNotesModal = () => {
    setNotesSaved(false);
    setShowNotesModal(false);
    setIndex(0);
  };

  const handleShowNewItemModal = () => {
    setShowNewItemModel(true);
    setIndex(selectedResult.length);
  };

  const handleCloseNewItemModal = () => {
    setShowNewItemModel(false);
    setIndex(0);
  };

  const handleAddInventoryItem = (
    item: InventoryItem,
    setValues: (values: InventoryItem[]) => void
  ) => {
    const updatedItems = [...selectedResult, item];
    setSelectedResult(updatedItems);
    setValues(updatedItems);
  };

  const handleConfirmArrival = async (
    index: number,
    value: boolean,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue(`[${index}].arrivalConfirmed`, value);
  };

  const handleNotesUpdate = (
    notes: string,
    setFieldValue: (field: string, value: any) => void,
    index: number
  ) => {
    setFieldValue(`[${index}].comments`, notes);
    setNotesSaved(true);
  };

  const checkArrivalConfirmed = (values: InventoryItem[]) => {
    return values.every((item) => item.arrivalConfirmed);
  };

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const references = await inventoryWorker.getLoadReferences(
          userAuthenticatedContext.token!,
          filterCompleted
        );

        setIsLoadReferencesLoaded(true);
        setLoadReferences(references);
      })();
    }
  }, [userAuthenticatedContext, inventoryWorker, filterCompleted]);

  if (!userAuthenticatedContext.scopes?.includes("edit:inventory-item")) {
    return (
      <Container>
        <Row>
          <Col className="mb-2">No permission to Inbound</Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Inbound</h1>
      {!isLoadReferencesLoaded ? (
        <Spinner style={{ marginLeft: "0.5em" }} size="sm" />
      ) : (
        <>
          <Row className="mb-4">
            <Col>
              <>
                <Form.Check
                  type="switch"
                  id="switch-completed"
                  onChange={(e) => {
                    setFilterCompleted(e.target.checked);
                    setSelectedOption("");
                    setIsLoadReferenceItemsLoaded(false);
                    setSelectedResult([]);
                  }}
                  label={
                    filterCompleted
                      ? "Showing Completed"
                      : "Showing Uncompleted"
                  }
                />
                {loadReferences.length > 0 ? (
                  <>
                    <Label>Select Load Reference</Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleOptionSelect}
                    >
                      <option value="" disabled hidden>
                        Please Select an Option
                      </option>
                      {loadReferences.map((reference, index) => (
                        <option key={index} value={reference.loadReference}>
                          {`${reference.loadReference} - ${reference.expectedDeliveryDate}`}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                ) : (
                  "Could not find any Load References"
                )}
              </>
            </Col>
          </Row>
          {showSuccess ? (
            <Row>
              <Col xs={12}>
                <Alert variant="success">
                  Inbound has been marked as completed.
                </Alert>
              </Col>
            </Row>
          ) : null}
          {!isLoadReferenceItemsLoaded ? (
            selectedOption !== "" ? (
              <Row>
                <Col>
                  <Spinner style={{ marginLeft: "0.5em" }} size="sm" />
                </Col>
              </Row>
            ) : null
          ) : (
            <Row>
              <Col xs={12}>
                <h2>{selectedResult[0].loadReferenceId}</h2>

                {inboundProcessed ? (
                  <Alert variant="warning">
                    This Inbound has already been processed. You can only view
                    the processed data.
                  </Alert>
                ) : null}

                {showSavingError ? (
                  <Alert variant="danger">
                    There was an error saving the data. Please try again.
                  </Alert>
                ) : null}
              </Col>
              <Col xs={12}>
                <Formik
                  initialValues={selectedResult}
                  enableReinitialize={true}
                  onSubmit={async (values, { resetForm }) => {
                    let error = false;
                    setShowSuccess(false);
                    setShowSavingError(false);

                    await Promise.all(
                      values.map(async (value) => {
                        if (value.arrivalConfirmed) {
                          value.arrivalDate = new Date().toISOString();
                        }

                        value.inboundCompleted = true;

                        try {
                          await inventoryWorker.updateInventoryItem(
                            value.id,
                            value,
                            userAuthenticatedContext.token!
                          );
                        } catch (updateError) {
                          console.error("Error Updating Item", updateError);
                          error = true;
                        }
                      })
                    );

                    if (error) {
                      setShowSavingError(true);
                    } else {
                      setSelectedOption("");
                      setSelectedResult([]);
                      setIsLoadReferenceItemsLoaded(false);
                      setShowSuccess(true);
                      resetForm();
                    }
                  }}
                >
                  {({
                    values,
                    isValid,
                    isSubmitting,
                    setFieldValue,
                    setValues,
                  }) => (
                    <FormikForm>
                      <Row xs={1} sm={2} md={4}>
                        {values && values.length > 0
                          ? selectedResult
                              .map((result, index) => ({
                                ...result,
                                originalIndex: index,
                              }))
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((result) => (
                                <InventoryInboundCard
                                  key={result.originalIndex}
                                  index={result.originalIndex}
                                  values={result}
                                  onShowModal={handleShowModal}
                                  onShowNotesModal={handleShowNotesModal}
                                  onHandleConfirmArrival={handleConfirmArrival}
                                  disabled={isSubmitting}
                                  setFieldValue={setFieldValue}
                                  inboundProcessed={inboundProcessed}
                                />
                              ))
                          : null}
                      </Row>
                      {!inboundProcessed ? (
                        <>
                          {checkArrivalConfirmed(values) ? (
                            ""
                          ) : (
                            <>
                              <Alert variant="warning">
                                <Form.Check
                                  type="checkbox"
                                  label="Not all items have been confirmed. Please check to override to allow submission. Any items not confirmed will be marked as not arrived!"
                                  checked={itemsConfirmedOverride}
                                  onChange={(e) =>
                                    setItemsConfirmedOverride(e.target.checked)
                                  }
                                />
                              </Alert>
                            </>
                          )}
                          <Button
                            style={{ marginRight: "10px" }}
                            disabled={isSubmitting}
                            onClick={handleShowNewItemModal}
                          >
                            Add New Item
                          </Button>
                          <Button
                            type="submit"
                            disabled={
                              !isValid ||
                              isSubmitting ||
                              (!checkArrivalConfirmed(values) &&
                                !itemsConfirmedOverride)
                            }
                          >
                            Submit
                          </Button>
                        </>
                      ) : null}
                      {showNewItemModal && (
                        <InboundNewItemModal
                          index={index}
                          isModalOpen={showNewItemModal}
                          handleCloseModal={handleCloseNewItemModal}
                          inventoryItems={values}
                          handleAddInventoryItem={(item) =>
                            handleAddInventoryItem(item, setValues)
                          }
                        />
                      )}
                      {showModal && (
                        <InboundModal
                          index={index}
                          isModalOpen={showModal}
                          handleCloseModal={handleCloseModal}
                          values={values}
                        />
                      )}
                      {showNotesModal && (
                        <InboundNotesModal
                          isModalOpen={showNotesModal}
                          handleCloseModal={handleCloseNotesModal}
                          setFieldValue={setFieldValue}
                          values={values}
                          handleNotesUpdate={handleNotesUpdate}
                          index={index}
                          notesSaved={notesSaved}
                        />
                      )}
                    </FormikForm>
                  )}
                </Formik>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(InboundLoadPage, {
  onRedirecting: () => <Loading />,
});
