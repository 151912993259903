import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import { CheckLg, Image, X } from "react-bootstrap-icons";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { CatalogItem, Client, InventoryItem } from "../../external";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import { UserAuthenticatedContext } from "../profile";
import Loading from "../loading";
import ImageCarousel from "../image-carousel";
import DropdownButton from "react-bootstrap/DropdownButton";

interface InventoryInboundModalProps {
  index: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  inventoryItems: InventoryItem[];
  handleAddInventoryItem: (inventoryItem: InventoryItem) => void;
}

type ModalView = "SEARCH" | "ITEM_DETAILS" | "ITEM_FORM";

interface CatalogSearchState {
  searchText: string;
  client?: Client;
  catalogItems: CatalogItem[];
  isLoading: boolean;
  isClientsLoaded: boolean;
}

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);
const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);
const createInventoryWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);

const validationSchema = Yup.object({
  quantity: Yup.number()
    .moreThan(0, "Must be greater than 0")
    .required("Required"),
  pallet: Yup.string().required("Required"),
  boxes: Yup.number()
    .moreThan(0, "Must be greater than 0")
    .required("Required"),
  comments: Yup.string(),
});

const PIC_WIDTH = 90;

const ClientDropdown: React.FC<{
  clients: Client[];
  selectedClient?: Client;
  onClientChange: (client?: Client) => void;
  disabled?: boolean;
}> = ({ clients, selectedClient, onClientChange, disabled }) => {
  const getClientTitle = (client?: Client) => {
    if (!client) return "All Clients";
    return (
      <span>
        {client.clientName}{" "}
        <span style={{ color: darkMode ? "lightcoral" : "coral" }}>
          {client.clientId}
        </span>
      </span>
    );
  };
  const darkMode = useCircleDarkMode();
  return (
    <DropdownButton
      disabled={disabled}
      variant={darkMode ? "outline-primary" : "outline-secondary"}
      aria-label="Select Client"
      title={getClientTitle(selectedClient)}
    >
      <Dropdown.Item onClick={() => onClientChange(undefined)}>
        <b>All Clients</b>
      </Dropdown.Item>
      <Dropdown.Divider />
      {clients
        .sort((a, b) => a.clientName.localeCompare(b.clientName))
        .map((client) => (
          <Dropdown.Item
            key={client.clientId}
            onClick={() => onClientChange(client)}
          >
            {getClientTitle(client)}
          </Dropdown.Item>
        ))}
    </DropdownButton>
  );
};

const CatalogItemRow: React.FC<{
  item: CatalogItem;
  onClick: () => void;
}> = React.memo(({ item, onClick }) => (
  <tr onClick={onClick}>
    <td style={{ maxWidth: `${PIC_WIDTH + 2}px` }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        {item.clientImageLinks?.length > 0 ? (
          <picture>
            <source srcSet={item.clientImageLinks[0]} />
            <img
              style={{ maxWidth: `${PIC_WIDTH}px` }}
              alt={item.clientItemId}
              src={item.clientImageLinks[0]}
            />
          </picture>
        ) : (
          <Image color="lightgrey" />
        )}
      </div>
    </td>
    <td>{item.clientId}</td>
    <td>{item.clientItemId}</td>
    <td>{item.name}</td>
    <td>{item.archived ? <CheckLg /> : <X />}</td>
  </tr>
));

const CatalogSearchView: React.FC<{
  state: CatalogSearchState;
  clients: Client[];
  onSearchTextChange: (text: string) => void;
  onClientChange: (client?: Client) => void;
  onSearch: () => void;
  onItemSelect: (item: CatalogItem) => void;
}> = ({
  state,
  clients,
  onSearchTextChange,
  onClientChange,
  onSearch,
  onItemSelect,
}) => {
  if (!state.isClientsLoaded) return <Loading />;

  return (
    <Row>
      <Col xs={12}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSearch();
          }}
        >
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={state.searchText}
              onChange={(e) => onSearchTextChange(e.target.value)}
              placeholder="Enter search terms..."
            />
            <ClientDropdown
              clients={clients}
              selectedClient={state.client}
              onClientChange={onClientChange}
            />
            <Button
              onClick={onSearch}
              disabled={!state.isLoading} // Disable button based on loading state
              variant="primary" // Bootstrap styling
            >
              Find Items
            </Button>
          </InputGroup>
        </Form>
      </Col>
      <Row>
        {state.isLoading ? (
          state.catalogItems.length > 0 ? (
            <Container fluid>
              <Table>
                <thead>
                  <tr style={{ fontWeight: "bolder" }}>
                    <th>Image</th>
                    <th>Client</th>
                    <th>Client Item ID</th>
                    <th>Name</th>
                    <th>Archived</th>
                  </tr>
                </thead>
                <tbody>
                  {state.catalogItems.map((item) => (
                    <CatalogItemRow
                      key={`${item.clientId}-${item.itemId}`}
                      item={item}
                      onClick={() => onItemSelect(item)}
                    />
                  ))}
                </tbody>
              </Table>
            </Container>
          ) : (
            <div>No Catalogue Items</div>
          )
        ) : (
          <Loading />
        )}
      </Row>
    </Row>
  );
};

const SelectedItemView: React.FC<{
  item: CatalogItem;
  onBack: () => void;
  onAddItem: () => void;
}> = ({ item, onBack, onAddItem }) => (
  <>
    <Button onClick={onBack} className="mb-3" variant="danger">
      Return to Search Results
    </Button>
    <Row className="justify-content-md-center">
      {item.clientImageLinks?.length > 0 ? (
        <ImageCarousel
          imageUrls={[...item.clientImageLinks]}
          maxWidth="300px"
        />
      ) : (
        "No Images"
      )}
    </Row>
    <Table striped bordered hover>
      <tbody>
        <tr>
          <td style={{ textAlign: "right" }}>Name</td>
          <td>{item.name}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Item ID</td>
          <td>{item.itemId}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Client Item ID</td>
          <td>{item.clientItemId}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Client</td>
          <td>{item.clientId}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Description</td>
          <td>
            {item.description && (
              <div
                className="csv-description-preview"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Archived?</td>
          <td>{item.archived ? "Yes" : "No"}</td>
        </tr>
      </tbody>
    </Table>
    <Button onClick={onAddItem} variant="success" className="mb-3">
      Add Item to Inbound?
    </Button>
  </>
);

const NewItemForm: React.FC<{
  item: CatalogItem;
  inventoryItems: InventoryItem[];
  onSubmit: (values: any) => Promise<void>;
  onError: (error: any) => void;
}> = ({ item, inventoryItems, onSubmit, onError }) => {
  const initialValues = useMemo(
    () => ({
      manualEntry: true,
      loadReferenceId: inventoryItems[0].loadReferenceId,
      clientId: inventoryItems[0].clientId,
      legacyPMID: inventoryItems[0].legacyPMID,
      catalogItemId: item.itemId,
      imageLinks: item.clientImageLinks,
      description: item.description,
      storefronts: [],
      quantity: 0,
      pallet: "",
      boxes: 0,
      comments: "",
    }),
    [item, inventoryItems]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await onSubmit(values);
          resetForm();
        } catch (error) {
          onError(error);
        }
      }}
    >
      {({
        values,
        isValid,
        isSubmitting,
        handleChange,
        handleBlur,
        touched,
        errors,
      }) => (
        <FormikForm>
          <Form.Group>
            <Form.Label>
              Quantity <Badge bg="danger">Required</Badge>
            </Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              value={values.quantity}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              isInvalid={touched.quantity && !!errors.quantity}
            />
            <Form.Control.Feedback type="invalid">
              {errors.quantity}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Pallet <Badge bg="danger">Required</Badge>
            </Form.Label>
            <Form.Control
              type="string"
              name="pallet"
              value={values.pallet}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              isInvalid={touched.pallet && !!errors.pallet}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pallet}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Boxes <Badge bg="danger">Required</Badge>
            </Form.Label>
            <Form.Control
              type="number"
              name="boxes"
              value={values.boxes}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              isInvalid={touched.boxes && !!errors.boxes}
            />
            <Form.Control.Feedback type="invalid">
              {errors.boxes}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              type="string"
              name="comments"
              value={values.comments}
              onChange={handleChange}
              disabled={isSubmitting}
              onBlur={handleBlur}
              isInvalid={touched.comments && !!errors.comments}
            />
            <Form.Control.Feedback type="invalid">
              {errors.comments}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            disabled={!isValid || isSubmitting}
            className="mt-3"
          >
            Submit
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

const InboundNewItemModal: React.FC<InventoryInboundModalProps> = ({
  isModalOpen,
  handleCloseModal,
  inventoryItems,
  handleAddInventoryItem,
}) => {
  const { token } = useContext(UserAuthenticatedContext);
  const clientsWorker = useWorker(createClientsWorker);
  const catalogItemsWorker = useWorker(createCatalogWorker);
  const inventoryWorker = useWorker(createInventoryWorker);

  const [view, setView] = useState<ModalView>("SEARCH");
  const [selectedItem, setSelectedItem] = useState<CatalogItem | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [state, setState] = useState<CatalogSearchState>({
    searchText: "",
    catalogItems: [],
    isLoading: true,
    isClientsLoaded: false,
  });

  useEffect(() => {
    if (!token || !isModalOpen) return;

    const fetchInitialData = async () => {
      try {
        setState((prev) => ({ ...prev, isLoading: false }));

        const [clients, catalogItems] = await Promise.all([
          clientsWorker.getClients(token),
          catalogItemsWorker.getCatalogItems(
            {
              sortDirection: "asc",
              sortField: "lastUpdatedTimestamp",
              page: 1,
              count: 400,
              text: "",
              clientId: undefined,
            },
            token
          ),
        ]);

        setClients(clients);
        setState((prev) => ({
          ...prev,
          catalogItems: catalogItems.data,
          isLoading: true,
          isClientsLoaded: true,
        }));
      } catch (err) {
        setError("Failed to load initial data");
        setState((prev) => ({ ...prev, isLoading: true }));
      }
    };

    fetchInitialData();
  }, [token, isModalOpen, clientsWorker, catalogItemsWorker]);

  const handleSearch = useCallback(async () => {
    if (!token || !state.isClientsLoaded) return;

    setState((prev) => ({ ...prev, isLoading: false }));

    try {
      const result = await catalogItemsWorker.getCatalogItems(
        {
          sortDirection: "asc",
          sortField: "lastUpdatedTimestamp",
          page: 1,
          count: 400,
          text: state.searchText,
          clientId: state.client?.clientId,
        },
        token
      );
      setState((prev) => ({
        ...prev,
        catalogItems: result.data,
        isLoading: true,
      }));
    } catch (err) {
      setError("Failed to search catalog items");
      setState((prev) => ({ ...prev, isLoading: true }));
    }
  }, [
    state.searchText,
    state.client,
    token,
    state.isClientsLoaded,
    catalogItemsWorker,
  ]);

  useEffect(() => {
    handleSearch();
  }, [state.client, state.searchText, handleSearch]);

  const handleClientChange = useCallback((client?: Client) => {
    setState((prev) => ({ ...prev, client }));
  }, []);

  const handleSearchTextChange = useCallback((text: string) => {
    setState((prev) => ({ ...prev, searchText: text }));
  }, []);

  const handleItemSelect = useCallback((item: CatalogItem) => {
    setSelectedItem(item);
    setView("ITEM_DETAILS");
  }, []);

  const handleAddItem = useCallback(() => {
    setView("ITEM_FORM");
  }, []);

  const handleBackToSearch = useCallback(() => {
    setSelectedItem(null);
    setView("SEARCH");
    setError(null);
  }, []);

  const handleSubmitItem = useCallback(
    async (values: any) => {
      if (!token) {
        setError("Authentication required");
        return;
      }

      try {
        const result = await inventoryWorker.createInventoryItem(values, token);
        handleAddInventoryItem(result);
        handleCloseModal();
      } catch (err) {
        console.error("Failed to add item to inbound");
        setError("Failed to add item to inbound");
      }
    },
    [token, inventoryWorker, handleAddInventoryItem, handleCloseModal]
  );

  const closeModal = useCallback(() => {
    setView("SEARCH");
    setSelectedItem(null);
    setError(null);
    handleCloseModal();
  }, [handleCloseModal]);

  const renderView = () => {
    switch (view) {
      case "ITEM_DETAILS":
        return selectedItem ? (
          <>
            <SelectedItemView
              item={selectedItem}
              onBack={handleBackToSearch}
              onAddItem={handleAddItem}
            />
            {error && <Alert variant="danger">{error}</Alert>}
          </>
        ) : null;
      case "ITEM_FORM":
        return selectedItem ? (
          <>
            <SelectedItemView
              item={selectedItem}
              onBack={handleBackToSearch}
              onAddItem={handleAddItem}
            />
            <NewItemForm
              item={selectedItem}
              inventoryItems={inventoryItems}
              onSubmit={handleSubmitItem}
              onError={(err) => setError(err.message)}
            />
            {error && <Alert variant="danger">{error}</Alert>}
          </>
        ) : null;
      case "SEARCH":
      default:
        return (
          <CatalogSearchView
            state={state}
            clients={clients}
            onSearchTextChange={handleSearchTextChange}
            onClientChange={handleClientChange}
            onSearch={handleSearch}
            onItemSelect={handleItemSelect}
          />
        );
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Search for an Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderView()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(InboundNewItemModal);
