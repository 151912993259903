import axios from "axios";

export type LocationPositionStore = "A" | "B" | "C" | "AB" | "BC" | "ABC";
export type LocationPosition = LocationPositionStore | "NON-RACK";
export type Warehouse = "Retail" | "Auction";

export const storeItemsLocation = async (
  item: string[],
  location: string,
  warehouse: Warehouse,
  rackPosition?: LocationPosition
) => {
  const storeLocationUrl = process.env.REACT_APP_POST_ITEM_LOCATION_URL;

  if (!storeLocationUrl) {
    throw new Error("REACT_APP_POST_ITEM_LOCATION_URL is not set.");
  }

  const storeLocationData = {
    itemIds: item,
    location: location,
    rackPosition: rackPosition === "NON-RACK" ? undefined : rackPosition,
    warehouse: warehouse,
  };

  try {
    const response = await axios.post(storeLocationUrl, storeLocationData);
    return response.data;
  } catch (error) {
    console.error("Error storing items in location", error);
    throw error;
  }
};

export const searchItemLocationById = async (itemId: string) => {
  const searchItemLocationUrl = `${process.env.REACT_APP_GET_ITEM_LOCATION_URL}/${itemId}`;

  if (!searchItemLocationUrl) {
    throw new Error("REACT_APP_GET_ITEM_LOCATION_URL is not set.");
  }

  try {
    const response = await axios.get(searchItemLocationUrl);
    return response.data;
  } catch (error) {
    console.error("Error searching item location by ID", error);
    throw error;
  }
};

export const searchItemsByLocationId = async (locationId: string) => {
  const searchItemsByLocationUrl = `${process.env.REACT_APP_GET_ITEMS_BY_LOCATION_URL}/${locationId}`;

  if (!searchItemsByLocationUrl) {
    throw new Error("REACT_APP_GET_ITEMS_BY_LOCATION_URL is not set.");
  }

  try {
    const response = await axios.get(searchItemsByLocationUrl);
    return response.data;
  } catch (error) {
    console.error("Error searching items by location ID", error);
    throw error;
  }
};

export type LocationType = "Racking" | "Unstructured";

export interface UnstructuredLocation {
  /**
   * Used in lieu of an aisle for locations that are not racking.
   */
  LocationName: string;

  /**
   * Optional sub-location (e.g., "Aisle 1", "Zone B")
   */
  SubLocation?: string;
}

export interface RackingLocation {
  /**
   * First part of the location, generally a letter
   */
  Aisle?: string;

  /**
   * How far along the aisle - generally a number
   */
  Bay?: number;

  /**
   * How high up on the racking - generally a number, starting at 1 from the floor.
   */
  Level?: number;

  /**
   * Sections on the racking. We work with ABC - pallets can take up 1, 1.5 (shown with a AB or BC), 2 or all 3 sections.
   */
  AvailableSections?: string;
}

export interface WarehouseLocationInput {
  /**
   * Name of the warehouse.
   */
  Warehouse: string;

  /**
   * Type of location to create.
   */
  LocationType: LocationType;

  /**
   * Racking location information.
   */
  Racking?: RackingLocation;

  /**
   * Unstructured location information. Used for locations that are not racking.
   */
  Unstructured?: UnstructuredLocation;
}

export interface WarehouseGetResponse {
  message: string;
  locations: WarehouseLocationInput[];
}

export const getAllLocations = async (warehouse: string) => {
  const getAllLocationsUrl = process.env.REACT_APP_GET_ALL_LOCATIONS_URL;

  if (!getAllLocationsUrl) {
    throw new Error("REACT_APP_GET_ALL_LOCATIONS_URL is not set.");
  }

  const url = `${getAllLocationsUrl}?warehouse=${warehouse}`;

  try {
    const response = await axios.get<WarehouseGetResponse>(url);
    return response.data;
  } catch (error) {
    console.error("Error getting all locations", error);
    throw error;
  }
};

export const putLocation = async (
  warehouse: string,
  locationType: LocationType,
  racking?: RackingLocation,
  unstructured?: UnstructuredLocation
) => {
  const putLocationUrl = process.env.REACT_APP_PUT_LOCATION_URL;
  if (!putLocationUrl) {
    throw new Error("REACT_APP_PUT_LOCATION_URL is not set.");
  }

  if (!warehouse || !locationType) {
    throw new Error("Warehouse and location type are required.");
  }

  const putFields: Record<
    string,
    string | UnstructuredLocation | RackingLocation
  > = {
    Warehouse: warehouse,
    LocationType: locationType,
  };

  if (locationType === "Racking") {
    if (!racking) {
      throw new Error("Racking location is required for Racking type.");
    }
    putFields.Racking = {
      Aisle: racking.Aisle,
      Bay: racking.Bay,
      Level: racking.Level,
      AvailableSections: racking.AvailableSections,
    };
  } else if (locationType === "Unstructured") {
    if (!unstructured) {
      throw new Error(
        "Unstructured location is required for Unstructured type."
      );
    }
    putFields.Unstructured = {
      LocationName: unstructured.LocationName,
      SubLocation: unstructured.SubLocation,
    };
  }

  try {
    const response = await axios.put(putLocationUrl, putFields);
    return response.data;
  } catch (error) {
    console.error("Error putting location", error);
    throw error;
  }
};

export interface OrganizedLocations {
  racking: RackingLocation[];
  unstructured: UnstructuredLocation[];
}

/**
 * Organizes locations into racking and unstructured categories.
 * @param locations Array of WarehouseLocationInput
 * @returns OrganizedLocations
 */
export const organizeLocations = (
  locations: WarehouseLocationInput[]
): OrganizedLocations => {
  const racking: RackingLocation[] = [];
  const unstructured: UnstructuredLocation[] = [];

  locations.forEach((location) => {
    if (location.LocationType === "Racking" && location.Racking) {
      racking.push(location.Racking);
    } else if (
      location.LocationType === "Unstructured" &&
      location.Unstructured
    ) {
      unstructured.push(location.Unstructured);
    }
  });

  return { racking, unstructured };
};
