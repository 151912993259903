import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  OrganizedLocations,
  RackingLocation,
  UnstructuredLocation,
  putLocation,
} from "../../workers/location";
import Loading from "../loading";
import { BoxSeam } from "react-bootstrap-icons";

const groupByAisle = (racking: RackingLocation[]) => {
  const grouped: { [aisle: string]: RackingLocation[] } = {};
  racking.forEach((rack) => {
    const aisle = rack.Aisle || "Unknown";
    if (!grouped[aisle]) {
      grouped[aisle] = [];
    }
    grouped[aisle].push(rack);
  });
  return grouped;
};

const combineUnstructuredLocations = (unstructured: UnstructuredLocation[]) => {
  const combined: { [locationName: string]: string[] } = {};
  unstructured.forEach((location) => {
    const name = location.LocationName;
    if (!combined[name]) {
      combined[name] = [];
    }
    if (location.SubLocation) {
      combined[name].push(location.SubLocation);
    }
  });
  return combined;
};

const LocationLayout = ({ locations }: { locations: OrganizedLocations }) => {
  const [groupedRacking, setGroupedRacking] = useState(
    groupByAisle(locations?.racking || [])
  );
  const [combinedUnstructured, setCombinedUnstructured] = useState(
    combineUnstructuredLocations(locations?.unstructured || [])
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{
    aisle: string;
    bay: number;
    level: number;
    availableSections: string;
  } | null>(null);
  const [newAisle, setNewAisle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState("racking");
  const [newUnstructuredLocation, setNewUnstructuredLocation] = useState("");
  const [newSubLocation, setNewSubLocation] = useState("");
  const [showUnstructuredModal, setShowUnstructuredModal] = useState(false);
  const [selectedExistingLocation, setSelectedExistingLocation] = useState("");

  const handleCellClick = (aisle: string, bay: number, level: number) => {
    const rack = groupedRacking[aisle]?.find(
      (r) => r.Bay === bay && r.Level === level
    );
    setSelectedLocation({
      aisle,
      bay,
      level,
      availableSections: rack?.AvailableSections || "",
    });
    setShowModal(true);
  };

  const handleSaveLocation = async () => {
    if (selectedLocation) {
      setIsSaving(true);
      const updatedRack: RackingLocation = {
        Aisle: selectedLocation.aisle,
        Bay: selectedLocation.bay,
        Level: selectedLocation.level,
        AvailableSections: selectedLocation.availableSections,
      };

      try {
        await putLocation("Golborne", "Racking", updatedRack);
        setGroupedRacking((prev) => {
          const updatedAisle = prev[selectedLocation.aisle] || [];
          const updatedRacks = updatedAisle.filter(
            (r) =>
              r.Bay !== selectedLocation.bay ||
              r.Level !== selectedLocation.level
          );
          updatedRacks.push(updatedRack);
          return { ...prev, [selectedLocation.aisle]: updatedRacks };
        });
        setShowModal(false);
      } catch (error) {
        console.error("Failed to save location", error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleAisleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase(); // Convert input to uppercase
    setNewAisle(value);
  };

  const handleAddAisle = () => {
    if (newAisle.trim() && !groupedRacking[newAisle]) {
      setGroupedRacking((prev) => ({
        ...prev,
        [newAisle]: [],
      }));
      setNewAisle("");
    } else if (groupedRacking[newAisle]) {
      alert("Aisle already exists!"); // Show an alert if the aisle already exists
    }
  };

  const handleAvailableSectionsChange = (value: string) => {
    setSelectedLocation((prev) =>
      prev ? { ...prev, availableSections: value } : null
    );
  };

  const handleAddUnstructuredLocation = async () => {
    const locationName =
      selectedExistingLocation || newUnstructuredLocation.trim();
    if (locationName) {
      setIsSaving(true);
      const unstructuredLocation = {
        LocationName: locationName,
        SubLocation: newSubLocation || undefined,
      };

      try {
        await putLocation(
          "Golborne",
          "Unstructured",
          undefined,
          unstructuredLocation
        );
        setCombinedUnstructured((prev) => ({
          ...prev,
          [locationName]: [
            ...(prev[locationName] || []),
            ...(newSubLocation ? [newSubLocation] : []),
          ],
        }));
        setNewUnstructuredLocation("");
        setNewSubLocation("");
        setSelectedExistingLocation("");
        setShowUnstructuredModal(false);
      } catch (error) {
        console.error("Failed to add unstructured location", error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleOpenUnstructuredModal = () => {
    setShowUnstructuredModal(true);
  };

  const handleCloseUnstructuredModal = () => {
    setShowUnstructuredModal(false);
    setNewUnstructuredLocation("");
    setNewSubLocation("");
  };

  const notConfiguredStyle: React.CSSProperties = {
    backgroundColor: "lightgray",
    fontSize: "0.8em",
    color: "darkgray",
    cursor: "pointer",
  };
  const availableSectionsStyle: React.CSSProperties = {
    backgroundColor: "lightgreen",
    cursor: "pointer",
  };

  const tableContainerStyle: React.CSSProperties = {
    overflowX: "auto",
    whiteSpace: "nowrap",
  };

  const columnStyle: React.CSSProperties = {
    textAlign: "center",
    minWidth: "80px",
  };

  const separatorRowStyle: React.CSSProperties = {
    backgroundColor: "#f8f9fa",
  };

  const maxBay = 30; // Assuming a maximum of 30 bays across all aisles
  const maxLevel = 5; // Assuming a maximum of 5 levels in the warehouse

  // Check if selected location has a valid AvailableSections value
  const isSaveButtonDisabled = !selectedLocation?.availableSections;

  if (!locations) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Tabs
        id="location-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || "racking")}
        className="mb-3"
      >
        <Tab eventKey="racking" title="Racking">
          <div className="mb-3">
            <Form>
              <Form.Label htmlFor="new-aisle">New Aisle</Form.Label>
              <InputGroup style={{ maxWidth: "300px" }}>
                <Form.Control
                  type="text"
                  id="new-aisle"
                  placeholder="New Aisle Letter/Name"
                  value={newAisle}
                  onChange={handleAisleInputChange}
                  className="mr-2"
                />
                <Button variant="success" onClick={handleAddAisle}>
                  Add Aisle
                </Button>
              </InputGroup>
            </Form>
          </div>
          {Object.keys(groupedRacking).length > 0 ? (
            <div style={tableContainerStyle}>
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ verticalAlign: "middle", ...columnStyle }}>
                      Aisle
                    </th>
                    <th style={{ verticalAlign: "middle", ...columnStyle }}>
                      Level
                    </th>
                    {Array.from({ length: maxBay }, (_, i) => (
                      <th key={i + 1} colSpan={1} style={columnStyle}>
                        Bay {i + 1}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedRacking).map(
                    ([aisle, racks], aisleIndex) => (
                      <React.Fragment key={aisle}>
                        {aisleIndex > 0 && (
                          <tr>
                            <td
                              colSpan={maxBay + 2}
                              style={separatorRowStyle}
                            ></td>
                          </tr>
                        )}
                        {Array.from({ length: maxLevel }, (_, levelIndex) => (
                          <tr key={`${aisle}-${maxLevel - levelIndex}`}>
                            {levelIndex === 0 && (
                              <td
                                rowSpan={maxLevel}
                                style={{
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                  fontSize: "2em",
                                  ...columnStyle,
                                }}
                              >
                                {aisle}
                              </td>
                            )}
                            <td style={columnStyle}>
                              Level {maxLevel - levelIndex}
                            </td>
                            {Array.from({ length: maxBay }, (_, bayIndex) => {
                              const rack = racks.find(
                                (r) =>
                                  r.Bay === bayIndex + 1 &&
                                  r.Level === maxLevel - levelIndex
                              );
                              return (
                                <td
                                  key={bayIndex + 1}
                                  style={
                                    rack?.AvailableSections
                                      ? {
                                          ...availableSectionsStyle,
                                          ...columnStyle,
                                        }
                                      : {
                                          ...notConfiguredStyle,
                                          ...columnStyle,
                                        }
                                  }
                                  onClick={() =>
                                    handleCellClick(
                                      aisle,
                                      bayIndex + 1,
                                      maxLevel - levelIndex
                                    )
                                  }
                                >
                                  {rack?.AvailableSections || "None"}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No racking locations available.</p>
          )}
        </Tab>
        <Tab eventKey="unstructured" title="Unstructured">
          <div className="mb-3">
            <Button variant="success" onClick={handleOpenUnstructuredModal}>
              Add Unstructured Location
            </Button>
          </div>
          {Object.keys(combinedUnstructured).length > 0 ? (
            <Row>
              {Object.entries(combinedUnstructured).map(
                ([locationName, subLocations], index) => (
                  <Col key={index} md={4} className="mb-3">
                    <Card>
                      <Card.Body>
                        <Card.Title>{locationName}</Card.Title>
                        {subLocations.length > 0 ? (
                          subLocations.sort().map((sub, subIndex) => (
                            <Card.Text>
                              <BoxSeam />
                              <span className="ms-2" key={subIndex}>
                                {sub}
                              </span>
                            </Card.Text>
                          ))
                        ) : (
                          <>
                            <Card.Text>
                              <em>No sub-locations available</em>
                            </Card.Text>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )
              )}
            </Row>
          ) : (
            <p>No unstructured locations available.</p>
          )}
        </Tab>
      </Tabs>

      {/* Modal for configuring location */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLocation && (
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Aisle</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedLocation.aisle}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Bay</Form.Label>
                    <Form.Control
                      type="number"
                      value={selectedLocation.bay}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Level</Form.Label>
                    <Form.Control
                      type="number"
                      value={selectedLocation.level}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Available Pallet Spaces</Form.Label>
                    <br />
                    <ButtonGroup className="w-100">
                      <Button
                        variant={
                          selectedLocation.availableSections === "A"
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => handleAvailableSectionsChange("A")}
                      >
                        1 (A)
                      </Button>
                      <Button
                        variant={
                          selectedLocation.availableSections === "AB"
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => handleAvailableSectionsChange("AB")}
                      >
                        2 (AB)
                      </Button>
                      <Button
                        variant={
                          selectedLocation.availableSections === "ABC"
                            ? "primary"
                            : "outline-primary"
                        }
                        onClick={() => handleAvailableSectionsChange("ABC")}
                      >
                        3 (ABC)
                      </Button>
                    </ButtonGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveLocation}
            disabled={isSaveButtonDisabled || isSaving}
          >
            {isSaving ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for adding unstructured location */}
      <Modal show={showUnstructuredModal} onHide={handleCloseUnstructuredModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Unstructured Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="existing-location">
              <Form.Label>Select Existing Location</Form.Label>
              <Form.Control
                as="select"
                value={selectedExistingLocation}
                onChange={(e) => setSelectedExistingLocation(e.target.value)}
              >
                <option value="">-- Select an existing location --</option>
                {Object.keys(combinedUnstructured).map(
                  (locationName, index) => (
                    <option key={index} value={locationName}>
                      {locationName}
                    </option>
                  )
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="new-unstructured-location" className="mt-3">
              <Form.Label>Or Enter New Location Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter location name"
                value={newUnstructuredLocation}
                onChange={(e) => setNewUnstructuredLocation(e.target.value)}
                disabled={!!selectedExistingLocation}
              />
            </Form.Group>
            <Form.Group controlId="new-sub-location" className="mt-3">
              <Form.Label>Sub-Location (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sub-location"
                value={newSubLocation}
                onChange={(e) => setNewSubLocation(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseUnstructuredModal}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAddUnstructuredLocation}
            disabled={
              isSaving ||
              (!newUnstructuredLocation.trim() && !selectedExistingLocation)
            }
          >
            {isSaving ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Saving...
              </>
            ) : (
              "Add Location"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default LocationLayout;
