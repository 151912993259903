import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { ArrowLeft, BoxSeam, GeoAlt } from "react-bootstrap-icons";
import CustomToggle from "../components/custom-toggle/custom-toggle";
import { useNavigate } from "react-router-dom";

const createLocationWorker = createWorkerFactory(
  () => import("../workers/location")
);

const ItemLocationSearchLocationPage = () => {
  const [searchType, setSearchType] = useState<"item" | "location">("item");
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [searchReportUrl, setSearchReportUrl] = useState<string | null>(null);

  const locationWorker = createLocationWorker();
  const navigate = useNavigate();

  const handleSearch = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    setSearchLoading(true);
    setSearchError(null);
    setSearchResults([]);

    try {
      let results;
      if (searchType === "item") {
        const filters = {
          item_id: [searchValue],
        };
        const filtersEncoded = encodeURIComponent(JSON.stringify(filters));

        const reportUrl = `https://dashboards.cluvio.com/dashboards/78dy-0wjx-q6ke/shared?filters=${filtersEncoded}&sharingToken=eebb4d84-692b-4d6d-9112-47391758b6b8`;

        setSearchReportUrl(reportUrl);

        results = await locationWorker.searchItemLocationById(searchValue);
      } else {
        results = await locationWorker.searchItemsByLocationId(searchValue);
      }
      const items =
        results.items || (Array.isArray(results) ? results : [results]);
      setSearchResults(items);
    } catch (err: any) {
      if (
        err.response?.data === "No items found for the given location" ||
        err.response?.data === "Item not found"
      ) {
        setSearchError(err.response.data);
      } else {
        setSearchError(`Error searching for ${searchType}.`);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleToggle = () => {
    setSearchType(searchType === "item" ? "location" : "item");
    setSearchResults([]);
    setSearchError(null);
    setSearchValue("");
  };

  return (
    <Container className="p-3">
      <Row className="justify-content-between align-items-center mb-3">
        <Col xs={8}>
          <Button variant="link" onClick={handleBack} className="p-0">
            <ArrowLeft size={24} /> Back
          </Button>
          <h3>
            Search{" "}
            <span
              className={`text-${
                searchType === "item" ? "primary" : "secondary"
              }`}
            ></span>
          </h3>
        </Col>
        <Col xs={4} className="text-end">
          <OverlayTrigger
            overlay={
              <Tooltip>
                Toggle to switch between searching by Item or Location ID.
              </Tooltip>
            }
          >
            <CustomToggle
              switchId="search-type-switch"
              checked={searchType === "location"}
              onChange={handleToggle}
              icons={[<BoxSeam />, <GeoAlt />]}
              ariaLabel="Toggle search type"
            />
          </OverlayTrigger>
        </Col>
      </Row>

      <Form onSubmit={handleSearch}>
        <Row className="mb-3">
          <Col xs={12}>
            <Form.Group controlId="searchValue">
              <Form.Label>
                {searchType === "item" ? "Item ID" : "Location ID"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={`Enter ${
                  searchType === "item" ? "item" : "location"
                } ID (e.g., ${searchType === "item" ? "ITM456" : "LOC123"})`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
              />
              <div className="small text-muted mt-1">
                <i className="bi bi-upc-scan"></i> Scan or enter ID manually.
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <Button
              onClick={handleSearch}
              disabled={searchLoading}
              className="w-100"
            >
              {searchLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Searching...
                </>
              ) : (
                "Search"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      {searchError && <Alert variant="danger">{searchError}</Alert>}
      {searchResults.length > 0 && (
        <>
          {searchResults.map((result, index) => (
            <Row key={index} className="mb-1" style={{ fontSize: "1.5em" }}>
              <Col>
                <strong>Item ID</strong> {result.id}
              </Col>
              <Col>
                <strong>Location </strong>
                {result.location}
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              {searchReportUrl && (
                <div className="iframe-wrapper">
                  <iframe
                    title="Item Location Report"
                    src={searchReportUrl as string}
                  ></iframe>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ItemLocationSearchLocationPage;
