import { CC2MasterSheetDataRow } from "../workers/sheets";

export const validateEntityId = (
  id: string,
  page: string,
  cc2SheetsData: CC2MasterSheetDataRow[]
) => {
  if (page === "pm") {
    if (!cc2SheetsData) {
      console.warn("cc2SheetsData is not defined");
      return false;
    }
    const pmIdPattern = /^(.+)-\d{5}$/;
    const match = id.match(pmIdPattern);
    if (!match) {
      return false;
    }

    return cc2SheetsData.some((item) => id.startsWith(item.skuSuffix));
  }

  // Assume any other page is valid, until we have more specific validation available
  return true;
};
